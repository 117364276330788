/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 10vw;
  top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
  right: 10vw !important;
  top: 30px !important;
}

/* Color/shape of close button cross */
// .bm-cross {
//   background: #bdc3c7;
// }

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-color: var(--color-theme-light);
  width: 100%;
  padding: 0 0;
  font-size: 1.15em;
  margin-top: -5px;
  overflow-y: hidden;
  height: calc(100vh - 3em) !important;
}

#sidebar::before {
  content: '';
  height: 4em;
  width: 100%;
  display: inline-block;
  background-color: var(--color-theme-light);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  width: 100%;
  color: white;
  padding: 0;
  height: 100% !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 900px) {
  #sidebar {
    display: none;
  }
  .bm-burger-button {
    display: none !important;
  }
}
