.shopping-list {
  .list-container {
    display: inline-block;
    text-align: left;
    width: 85%;
    padding: 0;
    margin: 0 auto;

    .shopping-list-input {
      margin: 5px 0 0 5px;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      * {
        align-self: center;
        margin: 0;
      }

      input {
        width: 137px;
        height: 27px;
        padding: 0 5px 0 5px;
        background: #ffffff;
        color: var(--color-theme-normal);
        font-size: 1rem;
      }
      input:disabled {
        background: #afafaf;
      }
    }

    .shopping-list-item {
      margin: 10px 0 0 5px;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      * {
        align-self: center;
        margin: 0;
      }
      img {
        height: 16px;
      }
      p {
        overflow: hidden;
        text-align: left;
        margin: 0;
        width: 70%;
      }

      img {
        margin: 0;
      }
    }
  }
}
