@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

@font-face {
  font-family: 'Knockout HTF49-Liteweight';
  src: local('Knockout HTF49-Liteweight'),
    url(./fonts/Knockout-HTF49-Liteweight.otf) format('truetype');
}

* {
  --color-theme-normal: #254e5a;
  --color-theme-light: #93bcc1;
  --color-theme-contrast: white;
  --header-height: 100px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

header,
#main-content > * {
  margin: 0 5%;
}

#main-content {
  background-color: var(--color-theme-normal);
  color: var(--color-theme-contrast);
  height: 100vh;
  display: block;
  overflow: scroll;
}

:is(h1, h2, h3, h4, h5) {
  font-family: 'Knockout HTF49-Liteweight', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
  text-align: left;
}

button.link-btn {
  display: block;
  height: 50px;
  width: fit-content;
  min-width: 140px;
  padding: 0 10px;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;

  background: var(--color-theme-light);
  border: none;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
}

// Tablet view
@media (min-width: 500px) {
  header,
  #main-content > * {
    margin: 0 10%;
  }
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}

// Desktop view
@media (min-width: 900px) {
  header,
  #main-content > * {
    margin: 0 10%;
  }
  h1 {
    font-size: 4.375rem;
  }
}

// Bigger Desktop view
@media (min-width: 1200px) {
  header,
  #main-content > * {
    margin: 0 15%;
  }
}
