.namer {
  input {
    margin-top: 30px;
    width: 250px;
    background-color: transparent;
    transition: transform 250ms ease-in-out;
    font-size: 2rem;
    line-height: 2vh;
    color: var(--color-theme-contrast);
    background-color: transparent;
    backface-visibility: hidden;
    padding: 12px 18px 12px 0;
    outline: 0;
    // border: 1px solid transparent;
    border: 1px solid var(--color-theme-light);
    border-radius: 0;
    padding: 5px 10px;
  }
}
