.hero {
  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .underlined {
    a {
      text-decoration: underline;
    }
  }
}
