.container {
  margin-bottom: 30px;
  max-width: 1020px;

  a {
    color: var(--color-theme-light);
    width: fit-content;
    text-decoration: none;
  }

  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 0px;
  }

  .centered {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    .disabled-btn {
      pointer-events: none;
      color: gray;
    }
  }

  .nav-buttons {
    height: 50px;
    z-index: 999;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    top: -1px;
    position: sticky;
    background-color: var(--color-theme-normal);

    .topleft {
      color: rgba(0, 0, 0, 0);
      cursor: pointer;
      position: relative;
      left: 0;

      img {
        height: 30px;
        margin-right: 10px;
      }
      * {
        vertical-align: middle;
      }
    }
    .topright {
      display: none;
      position: relative;
      right: 60px;
      color: rgba(0, 0, 0, 0);
      cursor: pointer;

      p {
        color: var(--color-theme-contrast);
        height: 30px;
        align-items: center;
        text-align: right;
        height: fit-content;
        line-height: 1.5;
      }
    }
  }

  h1 {
    margin-top: 50px;
    text-align: left;
  }

  img.slider {
    margin-top: 20px;
    margin-bottom: 30px;
    height: 20px;
    width: 100px;
  }
}

@media (min-width: 500px) {
  .container {
    h1 {
      font-size: 60px;
    }
    h3 {
      font-size: 0px;
    }
    .nav-buttons {
      .topleft {
        color: white;
      }
      div.topright {
        right: 45px;
      }
    }
  }
}

@media (min-width: 900px) {
  .container {
    h3 {
      font-size: 30px;
    }
    .nav-buttons {
      position: static;
      div.topright {
        right: 0;
        display: block;
      }
    }
    h1 {
      text-align: left;
    }
  }
}
