.mcp {
  form {
    margin-top: 20px;
    margin-bottom: 30px;
    display: grid;

    label {
      margin: 8px 0 8px 0;
      display: inline-flex;
      align-items: center;
      width: fit-content;

      svg {
        margin: 0 20px 0 0;
        height: 40px;
        width: 40px;
        flex-shrink: 0;
      }

      input[type='checkbox'] {
        margin: 0 20px 0 0;
        height: 30px;
        width: 30px;
        flex-shrink: 0;
      }

      input[type='checkbox']:checked {
        // Note that styling checkboxes is hell because of Chrome/Mozilla native styling
      }
    }
  }

  .single-pick-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    max-width: 714px;

    &.top-level {
      margin-bottom: 40px;
    }

    > button,
    a {
      margin: 10px 20px 10px 0;
      flex: 1;

      &:last-of-type {
        margin-right: 0;
      }
    }

    img {
      flex: 1;
      max-width: 100%;
      margin-bottom: 30px;
    }

    button {
      background: var(--color-theme-normal);
      color: var(--color-theme-contrast);
      border: 1px solid var(--color-theme-light);
      box-sizing: border-box;
      font-size: 12px;
      width: 100%;
      height: 60px;
      border-radius: 0;
      cursor: pointer;
    }
    button.active {
      background: var(--color-theme-light);
    }
    button:hover {
      background: var(--color-theme-light);
    }
  }
}

@media (min-width: 500px) {
  .mcp {
    form {    
      label {
        input[type='checkbox'] {
          height: 35px;
          width: 35px;
        }}}}
  .mcp {
    form {    
      label {
        svg {
          height: 40px;
          width: 40px;
        }}}}
  .container {
    .single-pick-buttons {
      button {
        font-size: 16px;
  
      }
    }
  }
}

@media (min-width: 700px) {
  .container {
    .single-pick-buttons {
      button {
        font-size: 16px;
      }
    }
  }
}
