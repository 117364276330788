.shopping-list {
  background-color: var(--color-theme-light);
  justify-content: flex-start;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 30px;
  // padding-bottom: 30px;

  span.floating-btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    font-size: 12px;
    border-radius: 50%;
    background-color: var(--color-theme-light);
    padding: 2px;
    vertical-align: middle;
  }

  p.desktop {
    display: none;
  }

  p.mobile {
    display: block;
  }

  .inline-flex {
    flex: 0;
    width: 85%;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    // margin: 30px auto 10px auto;
    background-color: inherit;
    // position: sticky;
    // top: 0;
    // z-index: 1;
    padding: 30px 0 30px 0;
    margin-right: auto;
    margin-left: auto;

    * {
      margin: 0;
    }
  }

  .scrollable {
    overflow: auto;
    flex: 1;
    margin-bottom: 20px;
  }

  p.centered {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    line-height: 1.5;
  }

  svg {
    margin: 0 auto;
  }
}
.btn-download-wrapper {
  margin-top: 30px;
}
.btn-download {
  fill: var(--color-theme-normal);
  height: 4rem;
  width: 4rem;
}
.shopper-buttons-mobile {
  flex-shrink: 0;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: 20px;
  background-color: inherit;
  // position: sticky;
  // bottom: 0;
  // z-index: 1;
  flex: 0;
  a {
    margin: 0px 20px 20px 20px;
    width: fit-content;
    text-decoration: none;
  }

  button {
    background: var(--color-theme-normal);
    width: 40vw;
    min-width: 180px;
    max-width: 220px;
    margin: 0;
  }
  .btn-light {
    background: var(--color-theme-light);
  }
}

@media (min-width: 900px) {
  .shopper-buttons-mobile {
    display: none;
  }
}

@media (min-width: 900px) {
  .shopping-list {
    background-color: #05373c;
    overflow-y: auto;
    height: 550px;

    p.desktop {
      display: block;
    }
  
    p.mobile {
      display: none;
    }
  }
  .btn-download {
    fill: var(--color-theme-normal);
    height: 4rem;
    width: 4rem;
  }
}
