@media (hover: hover) {
  .cardContainer {
    .hover .display-on,
    .hover-on,
    .hover-off {
      margin: 0;
      position: relative;
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
    }

    .hover .display-none,
    .hover .hover-on,
    .hover:hover .hover-off {
      display: none;
    }

    .hover:hover .hover-on {
      display: flex;
    }
  }
}
@media (min-width: 500px) {
  .cardContainer {
    margin: 0 0 20px 0;
    flex: 0 1 31.5%;
  }
}

// .cardContainer.details {
//   border: 10px solid var(--color-theme-contrast);
// }

.cardContainer {
  height: 244px;
  width: clamp(185px, 30%, 240px);
  background: var(--color-theme-contrast);
  display: inline-flex;
  flex-direction: column;
  //border: 10px solid var(--color-theme-normal);
  border: 10px solid var(--color-theme-contrast);
  box-sizing: border-box;
  text-align: center;
  align-items: center;

  // Hover display
  .hover {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .hover .display-on,
  .hover-on,
  .hover-off {
    height: 100%;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

  .hover .display-none,
  .hover .hover-on {
    display: none;
  }

  .details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: 15px;

    * {
      margin-top: 0;
    }

    .details-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      font-size: 10px;
      margin: 0;
      background: var(--color-theme-light);
      flex: 1;
      height: 43px;
      max-width: 49%;
      border: none;

      * {
        margin: 0;
      }
      .temperature-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-align: center;
      }
      button {
        border: none;
        width: 100%;
        height: 43px;
        font-size: 10px;
        background: var(--color-theme-normal);
        color: var(--color-theme-contrast);
      }
    }
  }
  button.floating-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    font-size: 25px;
    border-radius: 50%;
    background-color: var(--color-theme-light);
    color: white;
    position: absolute;
    // left: 0;
    bottom: -25px;
    padding: 22px;
    margin: 0;
    border: none;
    align-items: center;
    text-align: center;
  }
}

.cardHeader {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 13px;
  color: var(--color-theme-normal);
  text-align: center;
  height: 25px;
}

.other-names {
  font-size: 16px;
  word-break: break-all;
  margin: 13px 8px 0 8px;
  color: var(--color-theme-normal);
  text-align: center;
  height: 20%;
  width: 100%;
}

.cardLogo {
  display: flex;
  margin: 12px auto 0;
  max-width: 100%;
  height: 40%;
}

.cardRating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90px;
  margin: 13px auto 0;
}

.cardRatingLeaf {
  margin: 0;
  float: left;
  height: 100%;
}
