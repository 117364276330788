.shopper {
  .shopper-container {
    display: flex;
    justify-content: left;
    margin-bottom: 30px;
    margin-top: 30px;

    .shopper-text {
      max-width: 750px;
      min-height: 110px;
      // padding-right: 20px;
      flex: 1 1 70%;

      & > p {
        margin-top: 0px;
        line-height: normal;
      }
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
        line-height: 1.5;
      }
    }
    .shopper-buttons {
      // width: 220px;
      flex: 1 1 calc(30% - 20px);
      display: none;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;
      margin-left: 20px;

      a {
        display: inline-block;
        width: 100%;
        text-decoration: none;
        margin-bottom: 30px;
      }

      button {
        width: 100%;
        margin: 0;
      }
    }
    .shopping-list-container {
      min-width: 270px;
      display: none;
      flex-direction: column;
      min-height: 400px;
      flex-shrink: 0;
    }
  }

  span.floating-btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    font-size: 12px;
    border-radius: 50%;
    background-color: var(--color-theme-light);
    padding: 2px;
    vertical-align: middle;
  }

  .item-container {
    margin-top: 30px;
  }

  .item-container.mobile {
    padding-bottom: 30px;
    min-height: 250px;
    width: 100%;
    text-align: center;
    position: relative;
    overflow-x: hidden;

    .activeSlide,
    .lastSlide,
    .nextSlide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s linear;
    }

    .activeSlide {
      opacity: 1;
      transform: translateX(0);
    }
    .lastSlide {
      transform: translateX(-100%);
    }
    .nextSlide {
      transform: translateX(100%);
    }
    .prev,
    .next {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 20%;
      top: 40%;
      z-index: 999;
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
  }
  .item-container.desktop {
    display: none;
  }

  .shopper-recap {
    margin-bottom: 30px;
  }

  .recap-list {
    list-style: '-';
    padding-left: 4px;

    li {
      padding-left: 4px;
      margin-bottom: 6px;
    }
  }
}

@media (min-width: 580px) {
  .shopper {
    .shopper-container {
      .shopper-icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .shopper-icon {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-bottom: 1.5vh;
          flex: 1;
          max-width: 19%;

          * {
            font-size: 0.7rem;
            text-align: left;
            word-wrap: break-word;
            margin: auto 0 auto 0;
            width: 60%;
          }

          .icon {
            height: 4.5vh;
            width: 4.5vh;
            flex-shrink: 0;
          }
        }
      }

      .shopper-text {
        // h2 {
        //   text-align: left;
        // }
        .mobile {
          display: none;
        }
        .desktop {
          display: block;
        }
      }
    }
    .item-container.mobile {
      display: none;
    }
    .item-container.desktop {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-between;
      height: fit-content;
      // padding-right: 20px;
      max-width: 750px;
      flex: 1;
    }
    .item-container.desktop.add-empty::after {
      content: '';
      width: clamp(185px, 30%, 240px);
      margin: 0 0 20px 0;
      flex: 0 1 31.5%;
    }
  }
}

@media (min-width: 900px) {
  .shopper {
    .shopper-container {
      .shopper-buttons {
        display: block;
        min-width: 270px;
      }
      .shopping-list-container {
        display: block;
        margin-bottom: 30px;
      }
    }
  }
}
