.discard-picker {
  p {
    line-height: 1.5;
  }

  .highlighted {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  .discard-pick-buttons {
    display: block;
    margin-top: 30px;
    max-width: 850px;

    a {
      margin: 10px auto 0 auto;
      width: 90%;
    }

    button.flex-btn {
      margin: 10px 0 6px 0;
      font-size: 16px;
      height: 80px;
      display: inline-block;
      background: var(--color-theme-normal);
      color: var(--color-theme-contrast);
      border: 1px solid var(--color-theme-light);
      padding: 10px 60px;
    }
    .long {
      width: 100%;
    }
    button.flex-btn.active {
      background: var(--color-theme-light);
    }
    button.flex-btn:hover {
      background: var(--color-theme-light);
    }
    a.flex-btn:last-of-type {
      margin-right: 0;
    }
  }
}

@media (min-width: 500px) {
  .container {
    .discard-pick-buttons {
      a {
        width: 80%;
      }
      button.flex-btn {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 700px) {
  .container {
    .discard-pick-buttons {
      a {
        width: 60%;
      }
      button.flex-btn {
        font-size: 18px;
      }
    }
  }
}
